import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import cx from 'classnames';

import SwipeableViews from 'react-swipeable-views';

import FloatingFormSubmit from '../lib/SummonForm/FloatingFormSubmit';

import {
  getLandingActiveTab,
  getDefaultTab,
  getTabItems,
} from '../selectors/menu';

import {
  setLandingActive,
  setLandingActiveTab,
  reset,
} from '../redux/root/actionCreators';
import { resetSteps } from '../redux/summonForm/actionCreators';

import CONFIG from '../config/config.yaml';
import FORMS from '../config/forms.yaml';

class Landing extends React.Component {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({ push: PropTypes.func }),
    }),
  };

  static propTypes = {
    match: PropTypes.shape().isRequired,
    // REDUX
    dispatch: PropTypes.func.isRequired,
    landingActiveTab: PropTypes.number.isRequired,
    swipingDisabled: PropTypes.bool,
    tabItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    defaultTab: PropTypes.shape({ href: PropTypes.string }).isRequired,
  };

  static defaultProps = {
    swipingDisabled: false,
  };

  /**
   * Tell the whole app we're in the landing page (because in this page there is many tabs)
   * Redirect on the right tab if comming from external
   * @author Sylvain Pont
   */
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(setLandingActive());
    this.handleTabFromRouter(); // Keep in componentWillMount for this.props to be set
  }

  /**
   * When the url changes make sure the right tab is shown
   * That happens on tab's or menu's click (@see AppSkeleton handleTabChange)
   * @param  {object} nextProps
   * @author Sylvain Pont
   */
  componentWillReceiveProps(nextProps) {
    this.handleTabFromRouter(nextProps);
  }

  /**
   * Tell the whole app we're not in the landing page anymore
   * (because in this page there is many tabs)
   * @author Sylvain Pont
   */
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setLandingActive(false));
  }

  get mainFormTab() {
    const { tabItems } = this.props;
    if (!this.mainFormTabSaved) {
      let mainFormTabIndex = tabItems.findIndex(item => item.mainForm === true);
      mainFormTabIndex = mainFormTabIndex < 0 ? 0 : mainFormTabIndex;
      this.mainFormTabSaved = {
        ...tabItems[mainFormTabIndex],
        index: mainFormTabIndex,
      };
    }
    return this.mainFormTabSaved;
  }

  /**
   * Look at the URL and redirect to the corresponding tab
   * @param  {Object} [nextProps={}]
   * @author Sylvain Pont
   */
  handleTabFromRouter(nextProps = {}) {
    const { router } = this.context;
    const {
      dispatch,
      match,
      landingActiveTab,
      tabItems,
      defaultTab,
    } = this.props;
    const isInitialization = _.isEmpty(nextProps);
    const previousTab = isInitialization ? null : _.get(match, 'params.tab');
    const props = isInitialization ? this.props : nextProps;
    const nextTab = _.get(props, 'match.params.tab');
    const modify = _.get(props, 'match.params.modify');
    let resetUrl = false;

    // Set the active tab (only if there is one requested in the url, none might be set)
    if (nextTab && (isInitialization || nextTab !== previousTab)) {
      const nextTabIndex = tabItems.findIndex(
        item => item.href === `/${nextTab}`,
      );
      const savedActiveTab = landingActiveTab;
      // Update activeTab if it's not already the 'next' one
      if (savedActiveTab !== nextTabIndex) {
        dispatch(setLandingActiveTab(nextTabIndex));
      }
      if (nextTabIndex < 0) {
        resetUrl = true;
      }
    }

    // Reset steps on /<any tab>/modify
    if (modify === 'modify' || modify === 'reset') {
      if (modify === 'reset') {
        dispatch(reset());
      } else {
        dispatch(resetSteps());
      }
      // On modify/reset, always redirect to form
      router.history.push(this.mainFormTab.href);
    } else if (resetUrl || (_.isNil(nextTab) && isInitialization)) {
      router.history.push(defaultTab.href);
    }
  }

  handleTabSwipeChange = value => {
    const { dispatch } = this.props;
    dispatch(setLandingActiveTab(value));
  };

  render() {
    const { landingActiveTab, swipingDisabled, tabItems } = this.props;
    const isOnMainForm = landingActiveTab === this.mainFormTab.index;
    const showFloatingSubmit = isOnMainForm && CONFIG.mainFormFloatingSubmit;

    const tabClassName = index =>
      cx('tab', { noHeight: index !== landingActiveTab });

    return (
      <div>
        <SwipeableViews
          index={landingActiveTab}
          onChangeIndex={this.handleTabSwipeChange}
          resistance
          disabled={swipingDisabled}
        >
          {tabItems.map(({ Component, componentProps = {}, href }, index) => (
            <div
              key={`landing-tab-${href}-${index === landingActiveTab}`}
              className={tabClassName(index)}
            >
              <Component
                isActive={index === landingActiveTab}
                {...componentProps}
              />
            </div>
          ))}
        </SwipeableViews>
        {showFloatingSubmit && !FORMS.inscription.steps && (
          <div className="tab form-submit-fab-tabwrapper">
            <FloatingFormSubmit formName="inscriptionForm" />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ root, profile }) => ({
  landingActiveTab: getLandingActiveTab({ root }),
  swipingDisabled: root.swipingDisabled,
  tabItems: getTabItems({ root, profile }),
  defaultTab: getDefaultTab({ profile }),
});

export default connect(mapStateToProps)(Landing);
