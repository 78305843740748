import _ from 'lodash';

import {
  SET_FIRST_TIME_SUBMIT_FORM,
  SET_FIRST_TIME_FINISH_FORM,
  SET_VISITED_STEPS,
  SET_FINISHED,
  SET_SHOW_FORM,
  RESET_STEPS,
  RESET,
  BLOCK_FORM,
  RELEASE_FORM,
} from './actions';

const DEFAULT_STATE = {
  firstTimeSubmitForm: true,
  firstTimeFinishForm: true,
  visitedSteps: [],
  finished: false,
  showForm: true,
  blockedNames: [],
  blocked: false,
};

const setFirstTimeSubmitFormReducer = (state, { firstTimeSubmitForm }) => ({
  ...state,
  firstTimeSubmitForm,
});

const setFirstTimeFinishFormReducer = (state, { firstTimeFinishForm }) => ({
  ...state,
  firstTimeFinishForm,
});

const setStepsVisitedReducer = (state, { visitedSteps }) => ({
  ...state,
  visitedSteps: _.chain(visitedSteps)
    .castArray() // visitedSteps might be a single value or an array
    .map(s => +s) // visitedSteps entries might be numbers or strings
    .concat(state.visitedSteps) // Merge with already visited steps
    .uniq() // Make sure each step-entry appear only once
    .sort((a, b) => a - b) // Sort numerically
    .value(),
});

const setFinishedReducer = (state, { finished }) => ({ ...state, finished });

const setShowFormReducer = (state, { showForm }) => ({ ...state, showForm });

const resetStepsReducer = state => ({
  ...state,
  ...{
    showForm: DEFAULT_STATE.showForm,
    finished: DEFAULT_STATE.finished,
  },
});

const blockFormReducer = (state, { name }) => {
  if (name) {
    return {
      ...state,
      blockedNames: [...state.blockedNames, name],
      blocked: true,
    };
  }
  return {
    ...state,
    blocked: true,
  };
};

const releaseFormReducer = (state, { name }) => {
  const blockedNames = _.without(state.blockedNames, name);
  return {
    ...state,
    blockedNames,
    blocked: blockedNames.length !== 0,
  };
};

const summonForm = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case RESET:
      return DEFAULT_STATE;
    case SET_FIRST_TIME_SUBMIT_FORM:
      return setFirstTimeSubmitFormReducer(state, action);
    case SET_FIRST_TIME_FINISH_FORM:
      return setFirstTimeFinishFormReducer(state, action);
    case SET_VISITED_STEPS:
      return setStepsVisitedReducer(state, action);
    case SET_FINISHED:
      return setFinishedReducer(state, action);
    case SET_SHOW_FORM:
      return setShowFormReducer(state, action);
    case RESET_STEPS:
      return resetStepsReducer(state);
    case BLOCK_FORM:
      return blockFormReducer(state, action);
    case RELEASE_FORM:
      return releaseFormReducer(state, action);
    default:
      return state;
  }
};

export default summonForm;
