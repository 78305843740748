import { includes as _includes, upperFirst as _upperFirst } from 'lodash';
import mimeTypes from 'mime-types';
import fileExtension from 'file-extension';

const availableExtenssions = [
  'aac',
  'aiff',
  'ai',
  'avi',
  '_blank',
  'bmp',
  'c',
  'cpp',
  'css',
  'csv',
  'dat',
  'dmg',
  'doc',
  'dotx',
  'dwg',
  'dxf',
  'eps',
  'exe',
  'flv',
  'gif',
  'h',
  'hpp',
  'html',
  'ics',
  'iso',
  'java',
  'jpg',
  'js',
  'key',
  'less',
  'mid',
  'mp3',
  'mp4',
  'mpg',
  'odf',
  'ods',
  'odt',
  'otp',
  'ots',
  'ott',
  '_page',
  'pdf',
  'php',
  'png',
  'ppt',
  'psd',
  'py',
  'qt',
  'rar',
  'rb',
  'rtf',
  'sass',
  'scss',
  'sql',
  'tga',
  'tgz',
  'tiff',
  'txt',
  'wav',
  'xls',
  'xlsx',
  'xml',
  'yml',
  'zip',
];

const checkAvailableExt = (ext: string) => {
  let fixedExt;
  switch (ext) {
    case 'docx':
      fixedExt = 'doc';
      break;
    case 'xlsx':
      fixedExt = 'xls';
      break;
    case 'pptx':
      fixedExt = 'ppt';
      break;
    case 'jpeg':
      fixedExt = 'jpg';
      break;
    default:
      fixedExt = ext;
  }
  if (!_includes(availableExtenssions, fixedExt)) {
    fixedExt = '_blank';
  }
  return fixedExt;
};

const getExtFromFileInfos = (type: string, name: string) => {
  let ext = mimeTypes.extension(type) || fileExtension(name);
  ext = checkAvailableExt(ext);
  return ext;
};

const humanFileSize = (originalBytes: number, si = true) => {
  let bytes = originalBytes;
  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  do {
    bytes /= thresh;
    u += 1;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return `${bytes.toFixed(1)} ${units[u]}`;
};

function extractNameFromEmail(email: string) {
  const match = /^([^.]+)\.([^.]+)@/g.exec(email);
  if (match) {
    return {
      firstName: _upperFirst(match[1] || ''),
      lastName: _upperFirst(match[2] || ''),
    };
  }
  return { firstName: '', lastName: '' };
}

class PayloadError extends Error {
  payload: any;
  constructor(message: string, payload: any) {
    super(message);
    this.payload = payload;
  }
}

export {
  getExtFromFileInfos,
  humanFileSize,
  extractNameFromEmail,
  PayloadError,
};
