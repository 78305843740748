import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import VideogPlayer from 'videog-player';

const videoTheme = {
  palette: {
    background: 'rgb(0, 0, 0)',
    // primary: 'rgb(203, 0, 68)',
    controls: {
      background: 'rgb(245, 245, 245)',
      contrastText: 'rgb(62, 61, 64)',
    },
    progress: {
      primary: 'rgb(203, 0, 68)',
      background: 'rgb(224, 225, 221)',
      buffer: 'rgb(100, 99, 102)',
      over: 'rgb(100, 99, 102)',
    },
    tooltip: {
      background: 'rgb(100, 99, 102)',
      contrastText: 'rgb(250, 250, 250)',
    },
  },
};

const VideoPage = () => (
  <div>
    <Card>
      <CardContent>
        <div
          style={{
            fontSize: '24px',
          }}
        >
          <span
            style={{
              color: 'rgb(213, 43, 30)',
            }}
          >
            L’ÉMISSION
            <br />
            &nbsp;
          </span>
        </div>
        <VideogPlayer
          id="60849fc62c856049d0445cfba968f3a5719277a2"
          poster={3}
          autoplay={false}
          autoplayOnLandscape={false} /* not when multiple videos */
          theme={videoTheme}
        />
      </CardContent>
    </Card>
  </div>
);

export default VideoPage;
