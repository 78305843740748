import React from 'react';
import { injectIntl, intlShape } from 'react-intl';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { application as applicationMessages } from '../../i18n/dynamic';

const InscriptionsClosed = ({ intl }) => (
  <Card>
    <CardContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div style={{ margin: '20px 0' }}>
          <span
            style={{
              fontSize: '1.5em',
              fontWeight: 'bold',
              display: 'inline-block',
              marginBottom: '10px',
            }}
          >
            {intl.formatMessage(applicationMessages.inscriptionClosed)}
          </span>
          <br />
          <span
            style={{
              fontStyle: 'italic',
              display: 'inline-block',
              marginLeft: '10px',
            }}
          >
            &ndash; {intl.formatMessage(applicationMessages.theTeam)}
          </span>
        </div>
      </div>
    </CardContent>
  </Card>
);

InscriptionsClosed.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(InscriptionsClosed);
