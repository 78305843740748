import React from 'react';
import PropTypes from 'prop-types';
import onscrolling from 'onscrolling';
import _ from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/icons/Menu';

const styles = (theme, props) => {
  const {
    style: menuButtonStyle = {},
    stickyVisible: menuButtonStickyVisible = {},
    stickyHidden: menuButtonStickyHidden = {},
  } = props.menuButton;

  return {
    iconButtonRoot: {
      marginLeft: -8,
      ...menuButtonStyle,
    },
    menuRoot: {
      height: menuButtonStyle.height || '30px',
      ...menuButtonStickyHidden,
      color: menuButtonStickyHidden.color || 'rgb(51, 51, 51)',
      '&:hover': {
        color: menuButtonStickyHidden.hoverColor || 'rgb(102, 102, 102)',
      },
    },
    menuRootVisible: {
      height: menuButtonStyle.height || '30px',
      ...menuButtonStickyVisible,
      color: menuButtonStickyVisible.color || 'rgb(51, 51, 51)',
      '&:hover': {
        color: menuButtonStickyVisible.hoverColor || 'rgb(102, 102, 102)',
      },
    },
  };
};

class Sticky extends React.Component {
  static propTypes = {
    sticky: PropTypes.shape({
      style: PropTypes.shape(),
      title: PropTypes.string,
      titleStyle: PropTypes.shape(),
    }).isRequired,
    menuButton: PropTypes.shape({
      stickyHidden: PropTypes.shape({
        color: PropTypes.string,
        hoverColor: PropTypes.string,
      }),
      stickyVisible: PropTypes.shape({
        color: PropTypes.string,
        hoverColor: PropTypes.string,
      }),
      style: PropTypes.shape(),
      rootStyle: PropTypes.shape(),
    }),
    toggleMenu: PropTypes.func,
    AvatarComponent: PropTypes.func,
    // MUI Styles
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    menuButton: {},
    toggleMenu: _.noop(),
    AvatarComponent: _.noop,
  };

  /**
   * Set styles
   * Set default sticky style (hidden) in state
   * @param  {object} props
   * @author Sylvain Pont
   */
  constructor(props) {
    super(props);

    this.setStyles(props);
    const stickyStyles = this.setStickyStyles(props.sticky);

    this.state = {
      stickyStyle: stickyStyles.stickyHiddenStyle,
    };
  }

  setStyles(props) {
    const { sticky: { style: customStickyStyle = {} } = {} } = props;
    const { height: stickyHeight = '64px' } = customStickyStyle;

    this.styles = {
      wrapper: {
        position: 'relative',
      },
      stickyWrapper: {
        position: 'absolute',
        top: '0',
        width: '100%',
        height: '0',
      },
      titleDiv: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: stickyHeight,
        lineHeight: stickyHeight,
        flex: '1 1 auto',
      },
    };
  }

  /**
   * List of styles in wich sticky will navigate depending on scroll position
   * Set up the scroll listener in which state updates sticky style
   * @param  {object} stickyProps props.sticky
   * @author Sylvain Pont
   */
  setStickyStyles(stickyProps) {
    const {
      style: customStickyStyle = {},
      visibleStyle: customStickyVisibleStyle = {},
    } = stickyProps;

    const stickyStyle = { display: 'flex', justifyContent: 'space-between' };
    const stickyStyles = {
      // On splash screen
      stickyHiddenStyle: {
        ...stickyStyle,
        ...customStickyStyle,
        ...{
          color: 'transparent',
          backgroundColor: 'transparent',
        },
      },
      // On top of the screen
      stickyTransparentStyle: {
        ...stickyStyle,
        ...customStickyStyle,
        ...{
          color: 'transparent',
          backgroundColor: 'transparent',
          position: 'fixed',
          top: 0,
        },
      },
      // On scroll
      stickyVisibleStyle: {
        ...stickyStyle,
        ...customStickyStyle,
        ...customStickyVisibleStyle,
        ...{
          position: 'fixed',
          top: 0,
        },
      },
    };

    // Update the style of the component on scroll (hidden / transparent / visible)
    onscrolling(() => {
      const { stickyStyle: stateStickyStyle } = this.state;
      let newStyle = {};
      const header = document.getElementById('#header') || {};
      const headerHeight = _.get(header, 'offsetHeight', 0);
      const headerPaddingTop = +_.get(header, 'style.paddingTop', '0px').slice(
        0,
        -2,
      );
      // This is the background iamge used in header (hidden here)
      const relativeRefRect =
        this.relativeRef && this.relativeRef.getBoundingClientRect();
      // scrollTop: Distance of the top of the header to the top of the page :
      //   - positive: above (above screen - rest of the header might still be on screen)
      //   - negative / 0: below (on screen or below screen)
      const scrollTop = -relativeRefRect.top;
      const thirdOfHeaderImageHeight = headerHeight * (1 / 3);
      let stickyVisible = false;
      if (scrollTop < 0) {
        // Below the top of the screen (splash screen active) : hide
        newStyle = stickyStyles.stickyHiddenStyle;
      } else if (scrollTop < headerPaddingTop + thirdOfHeaderImageHeight) {
        // On top of screen (or close to) : transparent
        newStyle = stickyStyles.stickyTransparentStyle;
      } else {
        // Scrolled : Fully visible
        newStyle = stickyStyles.stickyVisibleStyle;
        stickyVisible = true;
      }
      if (!_.isEqual(stateStickyStyle, newStyle)) {
        this.setState({ stickyStyle: newStyle, stickyVisible });
      }
    });

    // To set default state
    return stickyStyles;
  }

  render() {
    const { classes, sticky, toggleMenu, AvatarComponent } = this.props;
    const { stickyStyle, stickyVisible } = this.state;
    const {
      wrapper: wrapperStyle,
      stickyWrapper: stickyWrapperStyle,
      titleDiv: titleDivStyle,
    } = this.styles;

    const { title: stickyTitle = {} } = sticky;

    return (
      <div
        style={wrapperStyle}
        ref={relativeRef => {
          this.relativeRef = relativeRef;
        }}
      >
        <div style={stickyWrapperStyle}>
          <AppBar style={stickyStyle} square position="sticky" elevation={0}>
            <Toolbar>
              <IconButton
                classes={{ root: classes.iconButtonRoot }}
                onClick={toggleMenu}
              >
                <Menu
                  classes={{
                    root: stickyVisible
                      ? classes.menuRootVisible
                      : classes.menuRoot,
                  }}
                />
              </IconButton>
              <div
                style={{
                  fontSize: '24px',
                  ...titleDivStyle,
                  visibility: stickyVisible ? 'unset' : 'hidden',
                }}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: stickyTitle }}
              />
              <AvatarComponent />
            </Toolbar>
          </AppBar>
        </div>
      </div>
    );
  }
}

const withStylesProps = stylesProp => Component => props => {
  const Comp = withStyles(theme => stylesProp(theme, props))(Component);
  return <Comp {...props} />;
};

export default withStylesProps(styles)(Sticky);
