import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { disableSwipe, enableSwipe } from '../../redux/root/actionCreators';

const style = {
  maxWidth: '100%',
  overflow: 'scroll',
};

const Image = ({ dispatch, children, alt, ...props }) => {
  const disable = () => {
    // TODO - don't disable if scrolled max !
    dispatch(disableSwipe());
  };
  const enable = () => {
    dispatch(enableSwipe());
  };
  return (
    <div style={style}>
      <img onTouchStart={disable} onTouchEnd={enable} alt={alt} {...props} />
    </div>
  );
};

Image.propTypes = {
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(Image);
