import React from 'react';
import PropTypes from 'prop-types';

import SummonField from './SummonField';

import { toInt } from '../normalizers';

const FieldCheckbox = props => {
  const { name, disabled, field } = props;
  const { label, labelStyle = {}, forced, ...input } = field;
  return (
    <SummonField
      key={`summonField-${name}`}
      reduxFormField={{
        name,
        format: forced ? () => true : value => value,
        parse: toInt, // https://redux-form.com/7.0.3/docs/valuelifecycle.md/
      }}
      component="checkbox"
      componentProps={{
        label: label && <span style={labelStyle}>{label}</span>,
        disabled: disabled || forced,
        ...input,
      }}
    />
  );
};

FieldCheckbox.prototype.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  field: PropTypes.shape({
    label: PropTypes.string.isRequired,
    forced: PropTypes.bool.isRequired,
  }).isRequired,
};

FieldCheckbox.prototype.defaultProps = {
  disabled: false,
};

export default FieldCheckbox;
