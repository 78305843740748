import _ from 'lodash';

// Normalize CSS styles between borwsers and OS
// http://nicolasgallagher.com/about-normalize-css/
// Pure CSS library
import 'normalize.css/normalize.css';

// Allow use of class="hlsj <language>" to highlight code
// https://highlightjs.org/
// `highlight.js` JS library
import 'highlight.js/styles/atom-one-light.css';

// Grid to display items (can be responsive)
// http://flexboxgrid.com/
// Pure CSS library
import 'flexboxgrid/dist/flexboxgrid.min.css';

// Tooltip
// https://www.npmjs.com/package/rc-tooltip
// `rc-tooltip` JS library
import 'rc-tooltip/assets/bootstrap.css';

import '../../styles/style.scss';

// https://github.com/typekit/webfontloader
import WebFontLoader from 'webfontloader';

// Material UI
// http://www.material-ui.com (check version in package.json)
import { createMuiTheme } from '@material-ui/core/styles'; // v1.x

import CUSTOMIZATION from '../../config/customization.yaml';

let fontFamily = '';

/**
 * Function to set the Material-UI lib theme
 * http://www.material-ui.com/#/customization/themes
 *    Load custom fonts
 *    Apply custom styles (colors mainly)
 * @return  {object}  muiTheme
 * @author Sylvain Pont
 */
const getTheme = () => {
  const {
    style: {
      font: fontConfig,
      palette,
      mui: { typography: configTypography, ...mui },
    },
  } = CUSTOMIZATION;
  const font = _.isString(fontConfig) ? { name: fontConfig } : fontConfig;

  // Roboto always here as backup font
  const fonts = {
    google: { families: ['Roboto:300,400,500,700', 'Material Icons'] },
  };
  if (font && font.name) {
    fontFamily = `"${font.name}", `;
    if (font.projectId) {
      // Fonts.com
      fonts.monotype = { projectId: font.projectId };
    }
  }
  if (font && font.google) {
    fonts.google.families.push(font.google);
  }
  WebFontLoader.load(fonts);

  fontFamily = `${fontFamily}Roboto, Helvetica, Arial, sans-serif`;
  return createMuiTheme({
    typography: {
      useNextVariants: true,
      // fontFamily,
      ...configTypography,
    },
    palette,
    overrides: {
      MuiCard: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          margin: '0px 10px 20px',
        },
      },
      MuiRadio: {
        root: {
          paddingTop: 6,
          paddingBottom: 6,
        },
      },
      MuiTypography: {
        body1: {
          fontSize: '1rem',
        },
        body2: {
          fontSize: '1rem',
        },
      },
    },
    ...mui,
  });
};

const getFontFamily = options => {
  getTheme(options);
  return fontFamily;
};

export { getTheme, getFontFamily };
