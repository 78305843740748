import React from 'react';

import CUSTOMIZATION from '../../config/customization.yaml';

/**
 * This component propose as simple as possible header
 * => will probably be rewritten for each project
 */
const Header = () => {
  const {
    header: { notSticky = {} },
  } = CUSTOMIZATION;
  const { title, titleImage, style = {}, titleStyle = {} } = notSticky;
  return (
    <div style={style} id="#header">
      <div
        style={{
          height: '100%',
          maxWidth: '800px',
          margin: 'auto',
        }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {titleImage && (
            <img
              alt="Title side"
              src={titleImage}
              className="hide-on-phone"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
              }}
            />
          )}
          <h1
            style={titleStyle}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
