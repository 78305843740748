import Welcome from './Pages/Welcome';
import Information from './Pages/Information';
import Closed from './Pages/Closed';
import Program from './Pages/Program';
import MainForm from './Form/MainForm';
import InscriptionsClosed from './Form/InscriptionsClosed';
import Invite from './Pages/Invite';
import Admin from './Pages/Admin';
import Video from './Pages/Video';
import DataPrivacy from './Pages/DataPrivacy';

export default {
  Welcome,
  Information,
  MainForm,
  InscriptionsClosed,
  Closed,
  Program,
  Invite,
  Admin,
  Video,
  DataPrivacy,
};
