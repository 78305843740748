import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import ical from 'ical-generator';
import { saveAs } from 'file-saver';
import { getMenuItems } from '../../selectors/menu';

class Calendar extends React.PureComponent {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({ push: PropTypes.func }),
    }),
  };

  static propTypes = {
    eventList: PropTypes.arrayOf(PropTypes.shape()),
    menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    eventList: [],
  };

  constructor(props) {
    super(props);
    const { menuItems } = props;
    this.state = {
      ready: false,
    };
    let mainFormTabIndex = _.findIndex(
      menuItems,
      item => item.mainForm === true,
    );
    mainFormTabIndex = mainFormTabIndex < 0 ? 0 : mainFormTabIndex;
    this.loginHref = menuItems[mainFormTabIndex].href;
  }

  componentDidMount() {
    this.checkEvents();
  }

  componentDidUpdate() {
    this.checkEvents();
  }

  // eslint-disable-next-line class-methods-use-this
  async saveCalendar() {
    // console.log(this.eventList);
    const cal = ical({ domain: 'github.com', name: 'my first iCal' });
    cal.createEvent({
      start: new Date(),
      end: new Date(new Date().getTime() + 3600000),
      summary: 'Example Event',
      description: 'It works ;)',
      location: 'my room',
      url: 'http://sebbo.net/',
    });
    const ics = cal.toString();
    const icsFile = new Blob([ics], {
      type: 'text/calendar;charset=utf-8',
    });

    const fileSaver = saveAs(icsFile, 'calendar.ics');

    return fileSaver.onwriteend;
  }

  checkEvents() {
    const { eventList } = this.props;
    const { router } = this.context;
    const events = eventList;
    const { ready } = this.state;
    if (!ready && events.length) {
      this.setState({ ready: true });
      this.saveCalendar()
        .then(() => {
          router.history.push(this.loginHref);
        })
        .catch(() => {
          router.history.push(this.loginHref);
        });
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = ({ root, profile }) => ({
  eventList: root.eventList,
  menuItems: getMenuItems({ profile }),
});

export default connect(mapStateToProps)(Calendar);
