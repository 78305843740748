import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

import Fab from '@material-ui/core/Fab';
import ActionDone from '@material-ui/icons/Done';
import { FormattedMessage } from 'react-intl';
import {
  isDirty,
  isValid,
  isSubmitting,
  hasSubmitSucceeded,
  submit,
} from 'redux-form';

class FloatingFormSubmit extends React.PureComponent {
  static propTypes = {
    form: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    dirty: PropTypes.bool,
    valid: PropTypes.bool,
    submitting: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
    blocked: PropTypes.bool,
  };

  static defaultProps = {
    dirty: false,
    valid: false,
    submitting: false,
    submitSucceeded: false,
    blocked: false,
  };

  constructor(props) {
    super(props);
    this.handleClick = _.bind(this.handleClick, this);
  }

  handleClick() {
    const { dispatch, form } = this.props;
    dispatch(submit(form));
  }

  render() {
    const { dirty, valid, submitting, submitSucceeded, blocked } = this.props;

    if (!dirty || submitting || submitSucceeded) {
      return null;
    }

    const disabled = !valid;
    return (
      <div className="form-submit-fab">
        <Fab
          onClick={this.handleClick}
          disabled={blocked || disabled}
          variant="extended"
          color="primary"
        >
          <ActionDone />
          <div style={{ marginLeft: 8 }}>
            <FormattedMessage id="form.submit" defaultMessage="Valider" />
          </div>
        </Fab>
      </div>
    );
  }
}

const mapStateToProps = ({ form, summonForm }, props) => ({
  form: props.formName,
  dirty: isDirty(props.formName)({ form }),
  valid: isValid(props.formName)({ form }),
  submitting: isSubmitting(props.formName)({ form }),
  submitSucceeded: hasSubmitSucceeded(props.formName)({ form }),
  blocked: summonForm.blocked,
});

export default connect(mapStateToProps)(FloatingFormSubmit);
