import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Markdown from '../../lib/SimpleContent/Markdown';

const Welcome = props => {
  const { roles } = props;
  const key = roles.includes('am') ? 'welcome-am' : 'welcome-pm';
  return (
    <div className="tab">
      <Card>
        <CardContent>
          <Markdown mdKey={key} />
        </CardContent>
      </Card>
    </div>
  );
};

Welcome.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
};

Welcome.defaultProps = {
  roles: [],
};

const mapStateToProps = state => ({
  ...state.profile,
});

export default connect(mapStateToProps)(Welcome);
