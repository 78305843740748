import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cx from 'classnames';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Fuse from 'fuse.js';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

// Rendering of the input text field itself
function renderInput(inputProps) {
  const { classes, inputRef, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      inputRef={inputRef}
      classes={{
        root: classes.inputRoot,
        input: classes.input,
      }}
      {...other}
    />
  );
}

// Each propsed suggestion will be rendered by this functions
function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) =>
          part.highlight ? (
            <strong key={String(index)}>{part.text}</strong>
          ) : (
            <span key={String(index)}>{part.text}</span>
          ),
        )}
      </div>
    </MenuItem>
  );
}

// Container of the rendered suggestions
function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;
  const { ref, ...restContainerProps } = containerProps;

  const autoPosition = containerRef => {
    if (containerRef) {
      ref(containerRef);
      const { bottom } = containerRef.getBoundingClientRect();
      if (bottom > window.innerHeight) {
        containerRef.style.bottom = `${containerRef.parentNode.offsetHeight}px`;
      }
    }
  };

  return (
    <div {...restContainerProps} ref={autoPosition}>
      <Paper square>{children}</Paper>
    </div>
  );
}

// What value to render on the suggestion
function getSuggestionValue(suggestion) {
  return suggestion.label;
}

// Fuzzy-finder initialization
function constructFuse(list) {
  const options = {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['label'],
  };
  return new Fuse(list, options);
}
// Fuzzy-finder initialization memoization
const getFuse = _.memoize(constructFuse);

// Find suggestions from the list that matches the value
function getSuggestions(list, value) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  const fuse = getFuse(list);

  return inputLength === 0 ? [] : fuse.search(inputValue).slice(0, 5);
}

const styles = () => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 10,
    left: 0,
    minWidth: '100%',
    maxWidth: '70vw',
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  smallContainer: {
    marginBottom: '0px',
  },
  smallInputRoot: {
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
  smallInput: {
    padding: '0',
  },
});

class InputSuggest extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
      }),
    ).isRequired,
    placeholder: PropTypes.string,
    classes: PropTypes.object.isRequired,
    deleteOnEnter: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    deleteValue: PropTypes.func,
    onClose: PropTypes.func,
    initialLabel: PropTypes.string,
    small: PropTypes.bool,
    focus: PropTypes.bool,
    inputProps: PropTypes.object,
  };

  static defaultProps = {
    className: '',
    placeholder: '',
    deleteOnEnter: false,
    deleteValue: _.noop,
    onClose: _.noop,
    initialLabel: '',
    small: false,
    focus: false,
    inputProps: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.initialLabel || '',
      suggestions: [],
    };
  }

  componentDidMount() {
    const { focus } = this.props;
    if (focus) {
      // Defer needed as input seems not ready
      _.defer(() => {
        this.inputRef.focus();
      });
    }
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    const { list } = this.props;
    this.setState({
      suggestions: getSuggestions(list, value),
    });
  };

  handleSuggestionsClearRequested = () => this.setState({ suggestions: [] });

  handleChange = (event, { newValue }) => {
    if (event.type === 'click') {
      this.handleSelect(newValue);
    } else {
      this.setState({ value: newValue });
    }
  };

  handleKeyDown = e => {
    const { value } = this.state;
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleSelect(value);
    } else if (e.shiftKey && e.key === 'Delete') {
      this.handleDelete(value);
    } else if (e.key === 'Escape') {
      this.handleQuit();
    }
  };

  handleSelect = value => {
    const { deleteOnEnter } = this.props;
    if (deleteOnEnter) {
      this.setState({ value: '' });
    } else {
      this.setState({ value });
    }
    this.validateValue();
  };

  handleDelete = value => {
    const { deleteValue } = this.props;
    const { suggestions } = this.state;
    deleteValue(value);
    let nextValue = '';
    const currentIndex = suggestions.findIndex(s => s.label === value);
    if (suggestions[currentIndex + 1]) {
      nextValue = suggestions[currentIndex + 1].label;
    } else if (suggestions[currentIndex - 1]) {
      nextValue = suggestions[currentIndex - 1].label;
    }
    this.setState({
      suggestions: suggestions.filter(s => s.label !== value),
      value: nextValue,
    });
  };

  handleQuit = () => {
    const { onClose } = this.props;
    onClose();
  };

  handleBlur = () => {
    this.validateValue();
  };

  validateValue() {
    const { list, onChange, initialLabel } = this.props;

    this.setState(({ value }) => {
      if (Object.values(list).findIndex(({ label }) => label === value) < 0) {
        onChange(initialLabel);
        return { value: initialLabel };
      }
      onChange(value);
      return null;
    });
  }

  render() {
    const { className, classes, placeholder, small, inputProps } = this.props;
    const { value, suggestions } = this.state;

    return (
      <Autosuggest
        theme={{
          container: cx(className, classes.container, {
            [classes.smallContainer]: small,
          }),
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderInputComponent={renderInput}
        inputProps={{
          classes: {
            input: cx(classes.input, { [classes.smallInput]: small }),
            inputRoot: cx(classes.inputRoot, {
              [classes.smallInputRoot]: small,
            }),
          },
          placeholder,
          value,
          onChange: this.handleChange,
          onKeyDown: this.handleKeyDown,
          onBlur: this.handleBlur,
          inputRef: inputRef => {
            this.inputRef = inputRef;
          },
          ...inputProps,
        }}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        focusInputOnSuggestionClick={false}
      />
    );
  }
}

export default withStyles(styles)(InputSuggest);
